<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/branch-course" class="btn btn-primary align-self-center ms-3">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.branchCourse.managementTitle") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.branchCourse.block.title')"
        :subTitle="$t('pages.module.branchCourse.block.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleBranchCourseBlockFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:user="{ row: record }">
            <router-link :to="'/ecommerce/user?userID=' + record.user.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.user.full_name }}</span>
            </router-link>
            <br>
            <a :href="'mailto:' + record.user.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.user.email }}
            </a>
        </template>
        <template v-slot:admin="{ row: record }">
            <span class="fw-bolder">{{ record.admin.full_name }}</span>
            <br>
            <a :href="'mailto:' + record.admin.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.admin.email }}
            </a>
        </template>
        <template v-slot:reason="{ row: record }">
            {{ record.reason ?? "-"}}
        </template>
        <template v-slot:date="{ row: record }">
            <div>
                <span class="fw-bolder">{{ $t('pages.module.branchCourse.block.cols.blockedAt') }}</span>
                <br>
                {{ record.blocked_at ? $moment(record.blocked_at).format("DD.MM.YYYY - HH:mm:ss") : $t('common.indefinite') }}
            </div>
            <div>
                <span class="fw-bolder">{{ $t('common.createdAt') }}</span>
                <br>
                {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
            </div>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_block" ref="blockModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_block_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_block_user_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="blockForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_block_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.block.cols.user') }}</label>
                                <el-form-item prop="user_id">
                                    <el-select
                                        class="w-100"
                                        v-model="form.data.user_id"
                                        :filterable="user.filterable"
                                        clearable
                                        remote
                                        :remote-method="remoteSearchUser"
                                        :loading="user.loading"
                                        :placeholder="$t('filters.module.branchCourse.reservation.userSearch')">
                                        <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.block.cols.reason') }}</label>
                                <el-form-item prop="reason">
                                    <el-input v-model="form.data.reason" type="textarea" rows="3" maxlength="512"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.module.branchCourse.block.cols.blockedAt') }}
                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.branchCourse.reservation.blockUser.informationBoxes.blockedAt')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="blocked_at">
                                    <el-date-picker class="w-100" v-model="form.data.blocked_at" type="datetime" :placeholder="$t('common.chooseDateAndTime')"  format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" clearable></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.module.branchCourse.block.cols.user"),
                    scopedSlots: {customRender: "user"}
                },
                {
                    name: this.$t("pages.module.branchCourse.block.cols.admin"),
                    scopedSlots: {customRender: "admin"},
                },
                {
                    name: this.$t("pages.module.branchCourse.block.cols.reason"),
                    scopedSlots: {customRender: "reason"}
                },
                {
                    name: this.$t("pages.module.branchCourse.block.cols.date"),
                    scopedSlots: {customRender: "date"},
                    class: "min-w-175px"
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            user: {
                filterable: true,
                loading: false,
                list: []
            },
        }
    },
    computed: {
        table() {
            return this.$store.state.module.branchCourse.reservation.block.table;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.branchCourse.block.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.branchCourse.managementTitle")]);

        this.$store.dispatch('module/branchCourse/reservation/block/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.title = this.$t("pages.module.branchCourse.block.new");
            this.showModal(this.$refs.blockModal);
        },
        onSubmit(){
            this.$refs.blockForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoints['module_branch_course_reservation_block'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.blockModal);
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    }).finally( () => {
                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/branchCourse/reservation/block/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "new":
                    this.newRecord();
                    break;

                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/branchCourse/reservation/block/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/branchCourse/reservation/block/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.list = data;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>